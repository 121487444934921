import React from "react";
import { Icon } from "react-weui";

//import styles
import "weui";
import "react-weui/build/packages/react-weui.css";
import "./index.css";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.props.logger.error(
      "页面报错 error=%s error_info=%s",
      JSON.stringify(error),
      JSON.stringify(errorInfo)
    );
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <div className="error-boundary-container">
          <Icon className="icon" size="large" value="warn" />
          <p className="tips">页面出错啦, 请稍后重试~</p>
        </div>
      );
    }

    return this.props.children;
  }
}
