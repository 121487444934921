import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import WxH5Pay from "./pages/WxH5Pay";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/h5/pay/wx" element={<WxH5Pay />} />
        <Route
          path="*"
          element={
            <main
              style={{
                padding: "1rem",
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              <p style={{ fontSize: "40px" }}>404</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
