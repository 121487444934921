/**
 * 本地日志上报到远程
 */

import axios from "axios";

export class LogReporter {
  queue = [];
  timerId = null;
  config = {
    flushWindowSize: 10,
    reportDelay: 500,
    token: "",
  };

  constructor(options) {
    Object.assign(this.config, options || {});
  }

  flush() {
    if (this.queue.length > 0) {
      const flushGroup = this.queue.splice(0, this.config.flushWindowSize);
      // 数据上报
      axios({
        method: "post",
        url: "/api/minigame/monitor",
        data: {
          logs: flushGroup,
        },
        headers: {
          Authorization: `Bearer ${this.config.token}`,
        },
      })
        .then(({ data }) => {
          if (data && data.code === 0) {
            this.flush();
          } else {
            this.queue.unshift(...flushGroup);
            this.timerId = setTimeout(() => {
              this.flush();
            }, this.config.reportDelay);
          }
        })
        .catch(() => {
          this.queue.unshift(...flushGroup);
          this.timerId = setTimeout(() => {
            this.flush();
          }, this.config.reportDelay);
        });
    } else {
      this.timerId = null;
    }
  }

  report(log) {
    try {
      this.queue.push(log);

      if (!this.timerId) {
        this.timerId = setTimeout(() => {
          this.flush();
        }, this.config.reportDelay);
      }
    } catch (e) {}
  }
}
