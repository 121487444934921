import React from "react";

import './bootstrap.css';
import './index.css';

const Home = () => {
  return (
    <>
      <section className="hero-area bg-1">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="block">
                <h1
                  className="wow fadeInDown"
                  data-wow-delay="0.3s"
                  data-wow-duration=".2s"
                >
                  冰橙科技
                </h1>
                <p
                  className="wow fadeInDown"
                  data-wow-delay="0.5s"
                  data-wow-duration=".5s"
                >
                  致力于用互联网技术帮助产品快速发展和推广, 为企业或个人产品赋能
                </p>
                <div
                  className="wow fadeInDown"
                  data-wow-delay="0.7s"
                  data-wow-duration=".7s"
                >
                  <a className="btn btn-home" href="#about" role="button">
                    了解我们
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section about bg-gray" id="about">
        <div className="container">
          <div
            className="row"
            style={{
              "flexDirection": "row-reverse",
              "alignItems": "center",
              "justifyContent": "space-between",
            }}
          >
            <div className="col-md-7 col-sm-7 wow fadeInLeft">
              <div className="content">
                <div className="sub-heading">
                  <h3>
                    我们提供成熟广泛的互联网信息技术咨询服务及软件系统开发解决方案,
                    帮助企业或者个人更好地推广自己的产品或者服务。我们的服务宗旨是
                    <strong>“客户至上, 质量第一”</strong>
                    。涵盖的领域有PC网页开发、移动端H5开发、微信小游戏开发及推广,
                    期待能为您的产品提供更优质的服务。
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 wow fadeInLeft">
              <div className="content">
                <img
                  style={{ width: "100%" }}
                  src="https://static.mcfrogtech.com/img/2.jpg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block">
                <p>重庆冰橙科技有限公司</p>
                <a
                  style={{ color: "#bababa", "textDecoration": "none" }}
                  href="https://beian.miit.gov.cn/"
                  target="_blank"
                >
                  渝ICP备2024024799号-27
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Home;
