import { useEffect, useState, useCallback, useRef } from "react";
import qs from "qs";
import axios from "axios";
import { Logger } from "../../utils/logger";
import { LogReporter } from "../../utils/log-reporter";
import ErrorBoundary from "../../components/ErrorBoundary";

import { Icon } from "react-weui";
import { EventEmitter } from "events";

//import styles
import "weui";
import "react-weui/build/packages/react-weui.css";
import "./index.css";

const search = window.location.search;

const queryObj = qs.parse(search, { ignoreQueryPrefix: true });

const logReporter = new LogReporter({
  token: queryObj.token,
});

const logger = new Logger({
  label: "@mcfrogtech/wxh5pay",
  logReporter,
});

let sdkOrderId = "";

const eventbus = new EventEmitter();

const syncOrderStatus = async (status) => {
  const { token } = queryObj;

  try {
    const { data } = await axios({
      method: "post",
      url: "/api/minigame/pay/syncstatus",
      data: {
        sdk_order_id: sdkOrderId,
        status,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (data.code === 0) {
      logger.error(
        "同步支付结果到后台成功 sdk_order_id=%s status=%s",
        sdkOrderId,
        status
      );
    } else {
      logger.error(
        "同步支付结果到后台失败 sdk_order_id=%s status=%s reason=%s",
        sdkOrderId,
        status,
        data.message
      );
    }
  } catch (e) {
    logger.error(
      "同步支付结果到后台失败 sdk_order_id=%s status=%s reason=%s",
      sdkOrderId,
      status,
      e && e.message
    );
  }
};

const requestWxPayment = (obj) => {
  function onBridgeReady() {
    // eslint-disable-next-line
    WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: obj.appId,
        timeStamp: obj.timeStamp,
        nonceStr: obj.nonceStr,
        package: obj.package,
        signType: obj.signType,
        paySign: obj.paySign,
      },
      function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          // 使用以上方式判断前端返回,微信团队郑重提示：
          // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          logger.info("支付成功");
          eventbus.emit("pay-success");
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          // 取消支付微信后台不回调，这里主动通知下服务端改订单状态
          syncOrderStatus("CANCELED");
          logger.info("支付取消");
          eventbus.emit("pay-cancel");
        } else {
          logger.info("支付失败");
          eventbus.emit("pay-fail");
        }
      }
    );
  }
  if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
      document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
    }
  } else {
    onBridgeReady();
  }
};

const unifiedOrder = async () => {
  const {
    token,
    app_id,
    cp_order_id,
    name,
    platform,
    notify_url,
    price,
    code,
    launch_id,
    os_platform,
  } = queryObj;

  const { data } = await axios({
    method: "post",
    url: "/api/minigame/pay/unifiedorder",
    data: {
      app_id,
      cp_order_id,
      name,
      platform,
      notify_url,
      price,
      code,
      launch_id,
      os_platform,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  logger.info("下单接口返回结果 %s", JSON.stringify(data));

  if (data.code === 0) {
    logger.info("下单成功, 开始调起微信支付");
    sdkOrderId = data.data.sdk_order_id;
    requestWxPayment(data.data.wx_h5_pay_params);
  } else {
    logger.error("下单失败", data.message);
    eventbus.emit(
      "order-error",
      data.message || "订单错误，请返回游戏重新下单~"
    );
  }
};

const getCode = () => {
  const code = queryObj.code;
  const local = window.location.href;

  logger.info("移动支付页面query=%s", JSON.stringify(queryObj));

  if (!code) {
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3ee02809120de559&redirect_uri=${encodeURIComponent(
      local
    )}&response_type=code&scope=snsapi_base&state=123abc#wechat_redirect`;
  } else {
    unifiedOrder().catch((err) => {
      logger.error("下单出错 %s", err && err.message);
      eventbus.emit("order-error", "下单失败，请稍后返回游戏重试~");
    });
  }
};

function WxH5Pay() {
  const [payStatus, setPayStatus] = useState("waiting");
  const [cnt, setCnt] = useState(3);
  const [tips, setTips] = useState("正在唤起微信支付，请稍等...");
  const timer = useRef(null);

  const startTimer = useCallback(() => {
    const timer = window.setInterval(() => {
      setCnt(cnt - 1);
    }, 1000);
    return timer;
  }, [setCnt, cnt]);

  const clearTimer = useCallback(() => {
    window.clearInterval(timer.current);
  }, []);

  const onPaySuccess = useCallback(() => {
    setPayStatus("success");
    setTips("支付成功, 请返回游戏查看~");
  }, []);

  const onPayFail = useCallback(() => {
    setPayStatus("warn");
    setTips("支付失败, 请退出重试~");
  }, []);

  const onPayCancel = useCallback(() => {
    setPayStatus("cancel");
    setTips("支付取消");
  }, []);

  const onOrderError = useCallback((message) => {
    setPayStatus("warn");
    setTips(message);
  }, []);

  useEffect(() => {
    getCode();

    eventbus.on("pay-success", onPaySuccess);
    eventbus.on("pay-fail", onPayFail);
    eventbus.on("pay-cancel", onPayCancel);
    eventbus.on("order-error", onOrderError);

    return () => {
      eventbus.off("pay-success", onPaySuccess);
      eventbus.off("pay-fail", onPayFail);
      eventbus.off("pay-cancel", onPayCancel);
      eventbus.off("order-error", onOrderError);
    };
  }, [onPaySuccess, onPayFail, onPayCancel, onOrderError]);

  useEffect(() => {
    if (cnt <= 0) {
      clearTimer();
      WeixinJSBridge.call("closeWindow"); // eslint-disable-line
    }
  }, [cnt, clearTimer]);

  useEffect(() => {
    if (payStatus !== "waiting") {
      timer.current = startTimer();
    }
  }, [payStatus, startTimer]);

  return (
    <div className="wxh5pay-container">
      <Icon className="icon" size="large" value={payStatus} />
      <p className="tips">{tips}</p>
      {payStatus !== "waiting" && (
        <p className="auto-tips">{`页面会在 ${cnt}s 后自动退出`}</p>
      )}
    </div>
  );
}

function App() {
  return (
    <ErrorBoundary logger={logger}>
      <WxH5Pay />
    </ErrorBoundary>
  );
}

export default App;
