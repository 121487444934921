/**
 * 自定义日志
 */

import { format } from "util";
import dayjs from "dayjs";

class Logger {
  config = {
    enableConsole: true,
    label: "@mcfrogtech/wxh5",
    logReporter: null,
  };

  constructor(options) {
    if (options) {
      Object.assign(this.config, options);
    }
  }

  reportLog(message) {
    if (this.config.logReporter) {
      this.config.logReporter.report(message);
    }
  }

  format(level, message, ...params) {
    // 格式化字符串
    const formattedMessage = format(message, ...params);
    // 添加额外通用参数
    return `[${
      this.config.label
    }]: ${dayjs().format('YYYY-MM-DD HH:mm:ss,SSS')} ${level} ${formattedMessage}`;
  }

  debug(message, ...params) {
    const str = this.format("DEBUG", message, ...params);
    if (this.config.enableConsole) {
      console.debug(str);
    }
    this.reportLog(str);
    return str;
  }

  info(message, ...params) {
    const str = this.format("INFO", message, ...params);
    if (this.config.enableConsole) {
      console.info(str);
    }
    this.reportLog(str);
    return str;
  }

  error(message, ...params) {
    const str = this.format("ERROR", message, ...params);
    if (this.config.enableConsole) {
      console.error(str);
    }
    this.reportLog(str);
    return str;
  }
}

export { Logger };
